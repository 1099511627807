.chromatic-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}

.chromatic-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  max-width: 800px;
}

.chromatic-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
}

.inhabitant-previews {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.inhabitant-preview {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
}

.inhabitant-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chromatic-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.chromatic-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.2s;
}

.chromatic-button:hover {
  transform: scale(1.05);
}

.chromatic-button.active {
  outline: 2px solid white;
}

/* Chromatic button colors */
.chromatic-button.red {
  background-color: #ff4444;
  color: white;
}

.chromatic-button.blue {
  background-color: #4444ff;
  color: white;
}

.chromatic-button.green {
  background-color: #44ff44;
  color: black;
}

.chromatic-button.white {
  background-color: #ffffff;
  color: black;
}

.chromatic-button.black {
  background-color: #444444;
  color: white;
}

/* Rename from ChromaticGallery.css */
.chroma-list {
  position: relative;
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.chroma-card {
  position: absolute;
  width: 50%;
  max-width: 800px;
  max-height: 60vh;
  aspect-ratio: 16/9;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transform-origin: center center;
}

.chroma-card.offscreen-top {
  transform: translateY(-150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.chroma-card.previous {
  transform: translateY(-35%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.chroma-card.focused {
  transform: translateY(0) scale(1);
  opacity: 1;
  z-index: 2;
}

.chroma-card.next {
  transform: translateY(35%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.chroma-card.offscreen-bottom {
  transform: translateY(150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.chroma-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.inhabitant-previews {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.inhabitant-preview {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
}

.inhabitant-preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ... rest of your existing styles ... */

/* Add these to your existing CSS */
.chroma-card[data-type="white"] {
  background: linear-gradient(to bottom, #ffffff, #f0f0f0);
  color: #000000;
  border: 2px solid #ffffff;
}

.chroma-card[data-type="blue"] {
  background: linear-gradient(to bottom, #0000ff, #000088);
  color: #ffffff;
  border: 2px solid #0000ff;
}

.chroma-card[data-type="black"] {
  background: linear-gradient(to bottom, #000000, #333333);
  color: #ffffff;
  border: 2px solid #000000;
}

.chroma-card[data-type="red"] {
  background: linear-gradient(to bottom, #ff0000, #880000);
  color: #ffffff;
  border: 2px solid #ff0000;
}

.chroma-card[data-type="green"] {
  background: linear-gradient(to bottom, #00ff00, #008800);
  color: #000000;
  border: 2px solid #00ff00;
}

/* Add glow effects for focused cards */
.chroma-card.focused[data-type="white"] {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.chroma-card.focused[data-type="blue"] {
  box-shadow: 0 0 20px rgba(0, 0, 255, 0.5);
}

.chroma-card.focused[data-type="black"] {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.chroma-card.focused[data-type="red"] {
  box-shadow: 0 0 20px rgba(255, 0, 0, 0.5);
}

.chroma-card.focused[data-type="green"] {
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
}

.chroma-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px);
  overflow: hidden;
}

.faction-slots, .frontier-slots {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 1rem 0;
}

.faction-slot, .frontier-slot {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
}

.faction-slot:hover, .frontier-slot:hover {
  transform: scale(1.1);
}

.tooltip {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.faction-slot:hover .tooltip, .frontier-slot:hover .tooltip {
  opacity: 1;
}

.chroma-card {
  position: relative;
  width: 50%;
  max-width: 800px;
  aspect-ratio: 16/9;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transform-origin: center center;
}

.chroma-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.chroma-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 2rem;
  gap: 2rem;
}

.faction-slots,
.frontier-slots {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1200px;
}

.faction-slot,
.frontier-slot {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.chroma-carousel {
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chroma-slide {
  position: relative;
  width: 80%;
  transition: transform 0.5s ease;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 2;
}

.nav-button.prev {
  left: 0;
}

.nav-button.next {
  right: 0;
}

.tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

@media (hover: hover) {
  .faction-slot:hover .tooltip,
  .frontier-slot:hover .tooltip {
    opacity: 1;
  }
}

@media (hover: none) {
  .tooltip {
    opacity: 1;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .slot-tooltip {
    opacity: 1;
    position: static;
    transform: none;
    display: block;
    text-align: center;
    margin-top: 0.5rem;
  }

  .slot {
    width: 60px;
    height: 60px;
  }

  .nav-button {
    width: 30px;
    height: 30px;
  }

  .chroma-content {
    width: 80%;
  }
}