.App {
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
}

.game-header {
  padding: 1vh 2vw;
  background-color: #282c34;
  color: white;
}

.game-header h1 {
  margin: 0;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
}

.topic-navigation {
  display: flex;
  justify-content: center;
  gap: clamp(0.5rem, 2vw, 1rem);
  padding: 1vh 1vw;
  background-color: #1a1a1a;
}

.topic-button {
  padding: clamp(0.3rem, 1vw, 0.8rem) clamp(0.8rem, 2vw, 1.5rem);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #444;
  color: white;
  font-size: clamp(0.8rem, 2vw, 1rem);
  transition: background-color 0.3s;
}

.topic-button.active {
  background-color: #666;
}

.content-section {
  overflow: hidden;
  padding: 1vh 2vw;
  display: flex;
  flex-direction: column;
}

.topic-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.topic-content {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}

.topic-image {
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.topic-text {
  flex: 1;
  text-align: left;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.feature {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .topic-content {
    flex-direction: column;
  }
  
  .topic-image {
    max-width: 100%;
  }

  .content-section {
    height: calc(100vh - 100px);
    padding: 0.5vh 1vw;
  }
}
