.inhabitant-list {
  position: relative;
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.navigation-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.navigation-arrow.prev {
  left: 20%;
}

.navigation-arrow.next {
  right: 20%;
}

.inhabitant-card {
  position: absolute;
  width: 35%;
  max-width: 500px;
  max-height: 80vh;
  aspect-ratio: 3/4;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transform-origin: center center;
}

/* Off-screen positions */
.inhabitant-card.offscreen-top {
  transform: translateY(-150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.inhabitant-card.previous {
  transform: translateY(-45%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.inhabitant-card.focused {
  transform: translateY(0) scale(1);
  opacity: 1;
  z-index: 2;
}

.inhabitant-card.next {
  transform: translateY(45%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.inhabitant-card.offscreen-bottom {
  transform: translateY(150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.inhabitant-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.inhabitant-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inhabitant-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.inhabitant-name {
  margin: 0;
  font-size: 1.25rem;
}

.gallery-dots {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 0.5rem;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease;
}

.dot.active {
  background: white;
}

.inhabitant-card.red { 
  box-shadow: 0 0 20px rgba(255, 68, 68, 0.3);
  border: 2px solid #ff4444; 
}
.inhabitant-card.blue { 
  box-shadow: 0 0 20px rgba(68, 68, 255, 0.3);
  border: 2px solid #4444ff; 
}
.inhabitant-card.green { 
  box-shadow: 0 0 20px rgba(68, 255, 68, 0.3);
  border: 2px solid #44ff44; 
}
.inhabitant-card.white { 
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  border: 2px solid #ffffff; 
}
.inhabitant-card.black { 
  box-shadow: 0 0 20px rgba(102, 102, 102, 0.3);
  border: 2px solid #666666; 
}

.inhabitants-section {
  height: calc(100vh - 140px);
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  overflow: hidden;
}

.content-section {
  height: calc(100vh - 140px);
  overflow: hidden;
}

@media (max-width: 768px) {
  .inhabitant-card {
    width: 55%;
    max-height: 70vh;
  }

  .navigation-arrow.prev {
    left: 5%;
  }

  .navigation-arrow.next {
    right: 5%;
  }
}