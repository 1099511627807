.factions-list {
  position: relative;
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.faction-card {
  position: absolute;
  width: 50%;
  max-width: 800px;
  max-height: 60vh;
  aspect-ratio: 16/9;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transform-origin: center center;
}

.faction-card.offscreen-top {
  transform: translateY(-150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.faction-card.previous {
  transform: translateY(-35%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.faction-card.focused {
  transform: translateY(0) scale(1);
  opacity: 1;
  z-index: 2;
}

.faction-card.next {
  transform: translateY(35%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.faction-card.offscreen-bottom {
  transform: translateY(150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

/* ... navigation arrows and other styles similar to other galleries ... */ 