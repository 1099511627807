.inhabitants-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
}

/* Add some responsive padding */
@media (max-width: 768px) {
  .inhabitants-section {
    padding: 1rem 0;
  }
}
