.frontier-section {
  height: calc(100vh - 140px);
  overflow: hidden;
  position: relative;
}

.frontier-viewport {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.frontier-card {
  position: absolute;
  width: 70%;
  max-width: 1000px;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center center;
}

/* Position states - similar to inhabitants */
.frontier-card.offscreen-top {
  transform: translateY(-150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.frontier-card.previous {
  transform: translateY(-45%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.frontier-card.current {
  transform: translateY(0) scale(1);
  opacity: 1;
  z-index: 2;
}

.frontier-card.next {
  transform: translateY(45%) scale(0.7);
  opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
}

.frontier-card.offscreen-bottom {
  transform: translateY(150%) scale(0.7);
  opacity: 0;
  pointer-events: none;
}

.frontier-image {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 8px;
}

.nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-arrow.left {
  left: 20%;
}

.nav-arrow.right {
  right: 20%;
}

.frontier-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.frontier-info h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.frontier-info p {
  font-size: 0.9rem;
  margin: 0;
}

.gallery-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  transition: background-color 0.3s ease;
}

.dot.active {
  background: white;
} 