.world-gallery {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
  box-sizing: border-box;
}

.world-gallery-container {
  width: 100%;
  height: 80%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
}

.main-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.nav-button {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: none;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.mechanic-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  color: white;
}

.mechanic-name {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
}

.mechanic-description {
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
}

.gallery-dots {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  padding: 0;
}

.dot.active {
  background: white;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .nav-button {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.2rem;
  }

  .mechanic-name {
    font-size: 1.2rem;
  }

  .mechanic-description {
    font-size: 0.9rem;
  }
} 